<!--
 * @Description: 办事项指南
-->

<template>
  <div class="page">
    <div class="top-image" :style="{ backgroundImage: bannerUrl }">
      <span class="title">个人办事项</span>
    </div>
    <div class="scroll-wrapper" id="scroll-wrapper" ref="scroll">
      <ul class="list" v-show="list && list.length > 0">
        <li
          class="list-item"
          v-for="(item, key) in list"
          :key="key"
          @click="jump(item)"
        >
          <van-image
            class="item-img"
            :src="item.imageCodeUrl ? item.imageCodeUrl.url : $defaultImg"
            fit="cover"
          />
          <div class="name">{{ item.itemName }}</div>
          <i class="iconfont iconarrow-right"></i>
        </li>
        <no-more v-if="noMore && !loading"></no-more>
      </ul>
    </div>
    <van-empty
      v-if="(list == null || list.length < 1) && !loading"
      description="暂无数据"
    />
  </div>
</template>

<script>
import { queryPageItemHome } from "@/api/policy/item";
import NoMore from "@/components/NoMore";
import BScroll from "better-scroll";

export default {
  data() {
    return {
      bannerUrl: `url(${process.env.VUE_APP_OSS_IMG_URL}/talentPolicy/个人12.png)`,
      list: [],
      isAuth: false,
      pageNum: 1,
      pageSize: 10,
      tabs: [{ name: "个人" }],
      currentTab: 0,
      loading: false,
      noMore: false,
    };
  },
  computed: {},
  mounted() {
    this.getList();
  },
  components: { NoMore },
  methods: {
    initScroll() {
      this.bscroll = new BScroll(this.$refs.scroll, {
        pullUpLoad: true,
        // scrollbar: true,
        pullDownRefresh: true,
        click: true,
        bindToWrapper: true,
      });
      this.bscroll.on("pullingDown", () => {
        this.refresherrefresh();
      });
      this.bscroll.on("pullingUp", () => {
        this.handleScrollBottom();
      });
    },
    refresherrefresh() {
      this.initPageData();
      this.getList();
      this.bscroll.finishPullDown();
    },
    //滚到底
    handleScrollBottom() {
      this.bscroll.finishPullUp();
      if (this.loading || this.noMore) {
        return;
      }
      this.pageNum++;
      this.getList();
    },
    /* 分页查询办事项 */
    getList() {
      this.loading = true;
      queryPageItemHome({
        applySubject: null,
        itemName: null,
        itemType: null,
        online: null,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        serviceObject: "1", //服务对象: 1-个人 2-法人
        themeTag: this.$route.query.themeTag,
      }).then((res) => {
        this.loading = false;
        let { success, data } = res;
        if (success) {
          //判断nomore
          if (this.pageNum === data.pages) {
            this.noMore = true;
          } else {
            this.noMore = false;
          }
          //合并列表数据
          if (this.pageNum == 1) {
            this.list = data.records || [];
            this.$nextTick(() => {
              this.initScroll();
            });
          } else {
            this.list = [...this.list, ...data.records];
            this.$nextTick(() => {
              this.bscroll.refresh();
            });
          }
        }
      });
    },
    /* 跳转详情 */
    jump(item) {
      this.$router.push({
        path: "/guideDetail",
        query: {
          itemId: item.itemId,
        },
      });
    },
    /*
     *@des: 切换标签页
     *@params: {index} Object 标签下标
     *@return:
     */
    onTabChange({ index }) {
      this.currentTab = index;
      this.pageNum = 1;
    },
    //init
    initPageData() {
      this.pageNum = 1;
      this.loading = false;
      this.noMore = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  background: #f9f9f9;
  .top-image {
    height: 3rem;
    padding: 1.03rem 0 0 0.5rem;
    background-size: cover;
    position: relative;
    z-index: 9;
    .title {
      font-size: 0.34rem;
      color: #fff;
      font-weight: bold;
    }
  }
  .auth-box {
    display: flex;
    padding: 0.3rem 0.3rem 0.2rem 0.3rem;
    justify-content: space-between;
    .remark {
      width: 3.9rem;
      height: 0.37rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.37rem;
    }
    .go-auth {
      width: 0.78rem;
      height: 0.37rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #3c37d2;
      line-height: 0.37rem;
    }
  }

  .scroll-wrapper {
    position: relative;
    height: calc(100vh - 3rem);
    // overflow: scroll;

    .list {
      display: flex;
      padding: 0.3rem 0.3rem 0.2rem 0.3rem;
      flex-direction: column;

      .list-item {
        width: 100%;
        margin-bottom: 0.2rem;
        padding: 0.1rem 0.2rem;
        background: #f9f9f9;
        border-radius: 0.2rem;
        box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.5rem;
        .item-img {
          width: 1.2rem;
          height: 1.2rem;
          display: block;
          border-radius: 0.6rem;
          overflow: hidden;
        }
        .name {
          width: 4.8rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
